import styled from 'styled-components'

export const AppHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 20px;
  background: #fff;
  .anticon {
    font-size: 20px;
    &.small-screen-collapse {
      display: none;
    }
  }
  .user {
    display: flex;
    align-items: center;
    .ant-btn {
      margin-left: 10px;
    }
  }
`
