import React, { memo, Suspense } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { Redirect } from 'react-router-dom'
import AppSidebar from '@/components/app-sidebar'
import AppHeader from '@/components/app-header'
import { renderRoutes } from 'react-router-config'
import { changeCollapsedAction } from '@/store/app/actionCreators'
import { Layout } from 'antd'
const { Content } = Layout

export default memo(function DefaultLayout(props) {
  // state and props
  const { collapsed } = useSelector(state => ({
    collapsed: state.getIn(['app', 'collapsed'])
  }), shallowEqual)
  const dispatch = useDispatch()

  // handle
  const handleToggleCollapsed = () => {
    dispatch(changeCollapsedAction(!collapsed))
  }

  return (
    <>
      {
        sessionStorage.getItem('sessionToken') ?
        <Layout>
          <AppSidebar/>
          {!collapsed && <Layout className="app-mask"
            onClick={e => handleToggleCollapsed()}/>}
          <Layout className="app-main"
            style={{marginLeft: collapsed ? '80px' : '200px'}}>
            <AppHeader/>
            <Content className="app-content">
              <Suspense fallback={<div>page loading</div>}>
                {renderRoutes(props.route.routes)}
              </Suspense>
            </Content>
          </Layout>
        </Layout>
        :  <Redirect to="/user/login"/>
      }
    </>
  )
})

