import React, { memo } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { changeCollapsedAction } from '@/store/app/actionCreators'

import { Button } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons'

import { AppHeaderWrapper } from './style'

const AppHeader = memo((props) => {
  // state and props
  const { collapsed } = useSelector(state =>({
    collapsed: state.getIn(['app', 'collapsed'])
  }), shallowEqual)
  const dispatch = useDispatch()

  // handle
  const getCollapsedIcon = () => {
    if(collapsed) {
      return (
        <>
          <MenuUnfoldOutlined className="big-screen-collapse"
            onClick={e => handelToggleCollapsed()} />
          <MenuUnfoldOutlined className="small-screen-collapse"
            onClick={e => handelToggleCollapsed()} />
        </>
      )
    }else {
      return (
        <>
          <MenuFoldOutlined className="big-screen-collapse"
          onClick={e => handelToggleCollapsed()} />
          <MenuFoldOutlined className="small-screen-collapse"
          onClick={e => handelToggleCollapsed()} />
        </>
      )
    }
  }

  // 切换汉堡
  const handelToggleCollapsed = () => {
    dispatch(changeCollapsedAction(!collapsed))
  }

  // 退出登录
  const onLogout = () => {
    sessionStorage.removeItem('sessionToken')
    props.history.push('/user/login')
  }

  return (
    <AppHeaderWrapper>
      {getCollapsedIcon()}
      <div className="user">
        超级管理员
        <Button onClick={e => onLogout()}>退出</Button>
      </div>
    </AppHeaderWrapper>
  )
})

export default withRouter(AppHeader)
