import React, { memo, useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import './style.scss'

import { NavLink, withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { Layout, Menu } from 'antd'
import {
  ReadOutlined,
  BulbOutlined,
  CameraOutlined,
  LinkOutlined,
  CommentOutlined,
  GlobalOutlined
} from '@ant-design/icons'
const { Sider } = Layout
const { SubMenu } = Menu

const AppSidebar = memo((props) => {
  // state and props
  const [openKeys, setOpenKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const { collapsed } = useSelector(state => ({
    collapsed: state.getIn(['app', 'collapsed'])
  }), shallowEqual)

  // hooks
  useEffect(() => {
    const activeMenu = props.location.state && props.location.state.activeMenu
    const pathname = props.location.pathname
    setSelectedKeys([activeMenu || pathname])
    const rank = pathname.split('/')
    switch(rank.length) {
      case 3:
        setOpenKeys([rank.slice(0,2).join('/')])
        break;
      case 4:
        setOpenKeys([rank.slice(0,2).join('/'), rank.slice(0,3).join('/')])
      break;
      default:
      break;
    }
  }, [props.location])

  const onOpenChange = (openKeys) => {
    setOpenKeys(openKeys)
  }

  return (
    <Sider
      breakpoint="lg"
      trigger={null}
      collapsed={collapsed}
      id="app-sidebar">
      <NavLink to="/" className="logo">
        <img src={require('@/assets/img/logo.png')} alt="logo"/>
      </NavLink>
      <Scrollbars style={{height: 'Calc(100vh - 64px)'}}>
        <Menu mode="inline"
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onOpenChange={openKeys => onOpenChange(openKeys)}>
          <SubMenu key="/article" icon={<ReadOutlined />} title="文章">
            <Menu.Item key="/article/list">
              <NavLink to="/article/list">文章列表</NavLink>
            </Menu.Item>
            <Menu.Item key="/article/type">
              <NavLink to="/article/type">文章类型</NavLink>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="/case" icon={<BulbOutlined />}>
            <NavLink to="/case">案例</NavLink>
          </Menu.Item>
          <SubMenu key="/photo" icon={<CameraOutlined />} title="相册">
            <Menu.Item key="/photo/list">
              <NavLink to="/photo/list">相册列表</NavLink>
            </Menu.Item>
            <Menu.Item key="/photo/type">
              <NavLink to="/photo/type">关键字</NavLink>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="/links" icon={<LinkOutlined />}>
            <NavLink to="/links">友链</NavLink>
          </Menu.Item>
          <Menu.Item key="/message" icon={<CommentOutlined />}>
            <NavLink to="/message">留言</NavLink>
          </Menu.Item>
          <Menu.Item key="/about" icon={<GlobalOutlined />}>
            <NavLink to="/about">关于</NavLink>
          </Menu.Item>
        </Menu>
      </Scrollbars>
    </Sider>
  )
})

export default withRouter(AppSidebar)
