import React from 'react'
import { Redirect } from 'react-router-dom'

import DefaultLayout from '@/layouts/default'
import BlankLayout from '@/layouts/blank'
import UserLayout from '@/layouts/user'

const routes = [
  {
    path: '/',
    component: BlankLayout,
    routes: [
      {
        path: '/user',
        component: UserLayout,
        routes: [
          {
            path: '/user',
            exact: true,
            render: () => (
              <Redirect to="/user/login"/>
            )
          },
          {
            path: '/user/login',
            component: React.lazy(() => import('@/pages/login'))
          }
        ]
      },
      {
        path: '/',
        component: DefaultLayout,
        routes: [
          {
            path: '/',
            exact: true,
            render: () => (
              <Redirect to="/article/list"/>
            )
          },
          {
            path: '/article/list',
            component: React.lazy(() => import('@/pages/article/list'))
          },
          {
            path: '/article/edit',
            component: React.lazy(() => import('@/pages/article/edit'))
          },
          {
            path: '/article/type',
            component: React.lazy(() => import('@/pages/article/type'))
          },
          {
            path: '/article/message',
            component: React.lazy(() => import('@/pages/article/message'))
          },
          {
            path: '/case',
            component: React.lazy(() => import('@/pages/case'))
          },
          {
            path: '/photo/list',
            component: React.lazy(() => import('@/pages/photo/list'))
          },
          {
            path: '/photo/type',
            component: React.lazy(() => import('@/pages/photo/type'))
          },
          {
            path: '/links',
            component: React.lazy(() => import('@/pages/links'))
          },
          {
            path: '/message',
            component: React.lazy(() => import('@/pages/message'))
          },
          {
            path: '/about',
            component: React.lazy(() => import('@/pages/about'))
          },
          {
            path: '*',
            component: React.lazy(() => import('@/pages/article/list'))
          },
        ]
      }
    ]
  },
  {
    path: '/register',
    component: React.lazy(() => import('@/pages/register')),
  },
  {
    path: '*',
    component: React.lazy(() => import('@/pages/404'))
  }
]

export default routes
