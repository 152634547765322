import React, { memo, Suspense } from 'react'

import {
  HashRouter
} from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import routes from '@/router'

export default memo(function App() {
  return (
    <HashRouter>
      {
        <Suspense fallback={<div>page loading</div>}>
          {renderRoutes(routes)}
        </Suspense>
      }
    </HashRouter>
  )
})

