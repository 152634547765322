import React, { memo, Suspense } from 'react'

import { renderRoutes } from 'react-router-config'

export default memo(function BlankLayout(props) {
  return (
    <>
      <Suspense fallback={<div>page loading</div>}>
        {renderRoutes(props.route.routes)}
      </Suspense>
    </>
  )
})
